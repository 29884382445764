import React from "react";
import { MdOutlineSportsKabaddi, MdOutlineSportsMartialArts,MdOutlinePhishing, MdOutlineCameraAlt, MdOutlineHistoryEdu, MdOutlineMinorCrash } from "react-icons/md";

const icon = <MdOutlineSportsKabaddi/>;
const icon1 = <MdOutlinePhishing/>;
const icon2 = <MdOutlineSportsMartialArts/>;
const icon3 = <MdOutlineCameraAlt/>;
const icon4 = <MdOutlineHistoryEdu/>;
const icon5 = <MdOutlineMinorCrash/>;

const renderIcons = (exp) => {
  switch (exp) {
    case '1':
      return icon;
    case '2':
      return icon1;
    case '3':
      return icon2;
    case '4':
      return icon3;
    case '5':
      return icon4;
    case '6':
      return icon5;
    }
  }

const AboutCard = ({ item, local }) => {
  return (
    <div
      style={{ background: `${local === "dark" ? "transparent" : item?.bg}` }}
      className="about-box dark:bg-transparent"
    >
      <div className="text-6xl text-[#1ED6EB] object-contain  block">
            {
              renderIcons(item?.id)
            }
        </div>
      <div className="space-y-2 break-all">
        <h3 className="dark:text-white text-xl font-semibold">{item?.title}</h3>
        <p className=" leading-8 text-gray-lite dark:text-[#A6A6A6]">
          {item?.des}
        </p>
      </div>
    </div>
  );
};
export default AboutCard;
