import React from 'react';
import Header from '../../Share/Header';
import userImage from "../../assets/images/about/avatar.jpg";
import downloadIcon from "../../assets/images/download.png";
import cvFile from "../../assets/images/cv.webp";
import {FaFacebookF, FaTwitter, FaDribbble, FaLinkedinIn} from "react-icons/fa";
import PageTitle from "../../Share/PageTitle";
import { FaGithub } from 'react-icons/fa6';

const Home = () => {
    return (
        <div className='bg-homeBg min-h-screen  dark:bg-homeBg-dark bg-no-repeat bg-center bg-cover bg-fixed  md:pb-16 w-full'>
            <Header/>
            <PageTitle title="Home"/>
            <div className="flex flex-col items-center h-[100vh] md:h-[90vh] lg:h-[80vh]  xl:h-[71vh]  justify-center" data-aos="fade">
                <img className="rounded-full w-[250px] h-[250px] 2xl:w-[280px] 2xl:h-[280px]"
                    src={userImage}
                    alt=""/>
                <h3 className="mt-6 mb-1 text-5xl font-semibold dark:text-white">
                    Yuri Lenzi Bartochevis
                </h3>
                <p className="mb-4 text-[#7B7B7B]">Backend developer</p>
                {/* Avatar Info End */}

                {/* Social information start */}
                <div className="flex space-x-3">
                    <a href="https://github.com/Yuri-Bartochevis" target="_blank" rel="noopener noreferrer">
                        <span className="socialbtn text-[#1773EA]">
                            <FaGithub/>
                        </span>
                    </a>
                    <a href="https://www.linkedin.com/in/yuri-lenzi-bartochevis-6638aab5/" target="_blank" rel="noopener noreferrer">
                        <span className="socialbtn text-[#0072b1]">
                            <FaLinkedinIn/>
                        </span>
                    </a>
                </div>
                {/* Social information start */}

                {/* Dowanload button start */}
                <a href={cvFile}
                    download
                    className="flex items-center bg-gradient-to-r from-[#2D84D3] to-[#2d84d3] duration-200 transition ease-linear hover:bg-gradient-to-l from-[#1ed6eb]  to-[#2D84D3ef] px-8 py-3 text-lg text-white rounded-[35px] mt-6">
                    <img src={downloadIcon}
                        alt="icon"
                        className="mr-2"/>
                    Download CV
                </a>

                {/* Dowanload button End */} </div>
        </div>
    );
};

export default Home;
