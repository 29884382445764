import { useEffect, useState } from "react";

// blog image small
import blogSmall2 from "../assets/images/blog_images/small/2.jpg";
import blogSmall1 from "../assets/images/blog_images/small/1.jpg";

import img1 from "../assets/images/slider/brand-1.png";
import img2 from "../assets/images/slider/brand-2.png";
import img3 from "../assets/images/slider/brand-3.png";
import img4 from "../assets/images/slider/brand-4.png";
import img5 from "../assets/images/slider/brand-5.png";

// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import { CgNotes } from "react-icons/cg";
import { FaBlogger, FaRegUser } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme") ?? "light";

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }


  // Elements for Blogs section
  const blogsData = [
    {
      id: "1",
      link:"https://ybartochevis.medium.com/how-to-set-up-your-monitor-environment-with-elk-ba28260e6c6c",
      imgSmall: blogSmall1,
      date: "17 April 2021",
      category: "Infrastructure",
      title: "How to set up your monitor environment with ELK.",
      bg: "#FCF4FF",
      description:
        ""
      },
    {
      id: "2",
      link: "https://www.linkedin.com/pulse/getting-familiar-kotlin-coroutines-yuri-lenzi-bartochevis",
      imgSmall: blogSmall2,
      date: "13 November 2022",
      category: "Coroutines",
      title: "Getting familiar with Kotlin Coroutines",
      bg: "#FFF0F0",
      description: "",
    }
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/home/about",
      icon: <FaRegUser />,
    },
    {
      id: "05",
      name: "Resume",
      link: "/home/resume",
      icon: <CgNotes />,
    },
    
    {
      id: "03",
      name: "Blogs",
      link: "/home/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "04",
      name: "Contact",
      link: "/home/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Slider image for Clients
  const sliderImg = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img1,
    img2,
    img3,
    img4,
    img5,
  ];

  // serviceArray items for about page
  const serviceArray = [
    {
      id: "1",
      title: "Jiu Jitsu",
      des: "",
      color: "#D566FF",
      bg: "#FCF4FF",
    },
    {
      id: "2",
      title: "Fishing",
      des: "",
      color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: "3",
      title: "Capoeira",
      des: "",
      color: "#8774FF",
      bg: "#FCF4FF",
    },
    {
      id: "4",
      title: "Photography",
      des: "",
      color: "#FF6080",
      bg: "#FFF4F4",
    },
    {
      id: "5",
      title: "Books",
      des: "",
      color: "#FF75D8",
      bg: "#FFF0F8",
    },
    {
      id: "6",
      title: "Old Cars",
      des: "",
      color: "#269FFF",
      bg: "#F3FAFF",
    },
  ];

  // educationArray items for Resume page
  const educationArray = [
    {
      id: 1,
      date: "2021 - 2022",
      title: "Masters - Full stack development",
      place: "FIAP - São Paulo",
      bg: "#FFF1FB",
    },

    {
      id: 2,
      date: "2015 - 2017",
      title: "Database Technologies",
      place: "SENAC - São Paulo",
      bg: "#FFF4F4",
    },
  ];
  // experiencesArray items for Resume page
  const experiencesArray = [
    {
      id: 1,
      date: "2022 - Present",
      title: "Software Engineer",
      place: "Wolt - Remote",
      bg: "#EEF5FA",
    },

    {
      id: 2,
      date: "2021-2022",
      title: "Software Engineer",
      place: "SPARETECH - Remote",
      bg: "#F2F4FF",
    },

    {
      id: 3,
      date: "2019 - 2021",
      title: "Java Developer",
      place: "IFood - São Paulo",
      bg: "#EEF5FA",
    },
  ];
  // awardsArray items for Resume page
  const certificationArray = [
    {
      id: 1,
      date: "2017",
      title: "1Z0-803 Java Programmer",
      place: "Oracle - Remote",
      bg: "#FCF4FF",
    }
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#FF6464",
      name: "Backend",
      number: "100",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "Infrastucture",
      number: "85",
    },
    {
      id: "03",
      color: "#5185D4",
      name: "Frontend",
      number: "55",
    },
    {
      id: "03",
      color: "#CA56F2",
      name: "Product Management",
      number: "75",
    },
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "Phone ",
      item1: "+49 174 8872858",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "yuri.bartochevis@hotmail.com",
      bg: "#EEFBFF",
    }
  ];

  return {
    handleTheme,
    check,
    local,
    singleData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    serviceArray,
    sliderImg,
    educationArray,
    experiencesArray,
    awardsArray: certificationArray,
    lineArray,
    contactArray,
  };
};

export default AllData;
